import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51903c36"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "JOToggleWrap"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gBtn = _resolveComponent("gBtn")!

  return (!_ctx.pToggleYn)
    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.pBtnList, (btn, index) => {
        return (_openBlock(), _createBlock(_component_gBtn, {
          style: _normalizeStyle(_ctx.pBtnSize? `width: ${_ctx.pBtnSize}%; margin-bottom: ${_ctx.pMBottom}; margin-left: 0px !important;`:''),
          class: _normalizeClass(["commonSmallText", index !== 0? 'mLeft-10' : '']),
          key: index,
          onClick: ($event: any) => (_ctx.changeBtnEvnt(index, btn)),
          pPointYn: _ctx.selectedBtnIndex === index,
          pBtnTitle: btn.title
        }, null, 8, ["style", "class", "onClick", "pPointYn", "pBtnTitle"]))
      }), 128))
    : (_ctx.pToggleYn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pBtnList, (btn, index) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (_ctx.changeBtnEvnt(index, btn)),
              class: _normalizeClass(["fl JOToggleBtn commonSmallText", _ctx.selectedBtnIndex === index? 'JOPointBtn' : 'JONormalBtn']),
              key: index,
              style: _normalizeStyle('width: '+ 100 / _ctx.pBtnList.length + '%;')
            }, _toDisplayString(btn.title), 15, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
}