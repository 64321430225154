import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "JOActiveBarWrap"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.pTabList)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pTabList, (tab, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "fl textLeft JOActiveBarPWrap",
            onClick: ($event: any) => (_ctx.switchtab(index, tab)),
            key: index,
            ref_for: true,
            ref: "tab"
          }, [
            _createElementVNode("p", {
              style: _normalizeStyle(_ctx.activebarWidth),
              class: _normalizeClass(["tabItem fontBold", {commonColor: index === _ctx.activetab, lightGray: index !== _ctx.activetab}]),
              innerHTML: tab.dispName
            }, null, 14, _hoisted_3)
          ], 8, _hoisted_2))
        }), 128)),
        _createElementVNode("div", {
          class: "JOActiveBar fl",
          ref: "activeBar",
          style: _normalizeStyle(_ctx.activebarWidth)
        }, null, 4)
      ]))
    : _createCommentVNode("", true)
}