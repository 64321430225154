import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61f5447a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "JOButtonWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gBtn = _resolveComponent("gBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pBtnList, (item, index) => {
      return (_openBlock(), _createBlock(_component_gBtn, {
        key: index,
        style: {"padding":"5px 10px"},
        pBtnTitle: item.btnTitle,
        pPointYn: item.pointYn,
        onClick: item.onClickFn
      }, null, 8, ["pBtnTitle", "pPointYn", "onClick"]))
    }), 128))
  ]))
}