interface CurrentProject {
  name: string
  key: string | number
}

interface Subject {
  name: string
  key: number
  categoryName: string
  categoryKey: number
}

interface SearchedJuso {
  address: string
  roadAddress: string
  jibunAddress: string
  bcode: string
  coord: { lat: number | string; lng: number | string }
}

interface State {
  currentProject: CurrentProject
  subjectList: Subject[]
  subjectKeyArray: number[]
  selectedSubjectKeys: string
  searchedJuso: SearchedJuso
}

const JO_MAP = {
  namespaced: true,
  state: {
    currentProject: {} as CurrentProject,
    subjectList: [] as Subject[],
    subjectKeyArray: [] as number[],
    selectedSubjectKeys: '',
    searchedJuso: {} as SearchedJuso
  } as State,
  getters: {
    getCurrentProject(state: State) {
      return state.currentProject
    },
    getSubjectList(state: State) {
      return state.subjectList
    },
    getSelectedSubjectKeys(state: State) {
      let subjectKeyStr = ''
      if (state.subjectKeyArray.length === 1) {
        subjectKeyStr = String(state.subjectKeyArray[0])
      } else if (state.subjectKeyArray.length > 1) {
        for (const key of state.subjectKeyArray) {
          if (subjectKeyStr) {
            subjectKeyStr = String(state.subjectKeyArray[0])
          }
          subjectKeyStr = `${subjectKeyStr}, ${key}`
        }
      }
      return subjectKeyStr
    },
    getSearchedJuso(state: State) {
      return state.searchedJuso
    }
  },
  mutations: {
    setCurrentProject(state: State, project: CurrentProject) {
      state.currentProject = project

      console.log('===========[store] selected project', state.currentProject)
    },
    setSubjectList(state: State, subject: Subject) {
      state.subjectList.push(subject)

      console.log('===========[store] selected subjects', state.subjectList)
    },
    selectSubject(state: State, subjectKey: number) {
      state.subjectKeyArray.push(subjectKey)
      console.log('===========[store] add subject keys', state.subjectKeyArray)
    },
    unselectSubject(state: State, subjectKey: number) {
      const indexToRemove = state.subjectKeyArray.indexOf(subjectKey)
      if (indexToRemove !== -1) {
        state.subjectKeyArray.splice(indexToRemove, 1)
      }
      console.log(
        '===========[store] remove subject keys',
        state.subjectKeyArray
      )
    },
    searchJuso(state: State, searchResult: SearchedJuso) {
      state.searchedJuso = searchResult
      console.log('===========[store] searched Juso', state.searchedJuso)
    }
  },
  actions: {
    selectCurrentProject(context: any, project: CurrentProject) {
      context.commit('setCurrentProject', project)
    },
    setSubjectList(context: any, subject: Subject) {
      context.commit('setSubjectList', subject)
    },
    addSelectedSubjectKey(context: any, subjectKey: number) {
      context.commit('selectSubject', subjectKey)
    },
    removeSelectedSubjectKey(context: any, subjectKey: number) {
      context.commit('unselectSubject', subjectKey)
    },
    setSearchedJuso(context: any, searchResult: SearchedJuso) {
      context.commit('searchJuso', searchResult)
    }
  }
}

export default JO_MAP
