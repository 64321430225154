import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a45d892e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled", "readonly", "placeholder"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.pInputType === 'N')
    ? _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 0,
        disabled: _ctx.pDisabledYn,
        class: "JOCommonInputStyle",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mInputObj.val) = $event)),
        type: "number",
        name: "",
        id: ""
      }, null, 8, _hoisted_1)), [
        [_vModelText, _ctx.mInputObj.val]
      ])
    : (_ctx.pInputType === 'I')
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          disabled: _ctx.pDisabledYn,
          readonly: _ctx.pReadonly,
          placeholder: _ctx.pPlaceHolder,
          class: "JOCommonInputStyle",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mInputObj.val) = $event)),
          type: "text",
          name: "",
          id: ""
        }, null, 8, _hoisted_2)), [
          [_vModelText, _ctx.mInputObj.val]
        ])
      : (_ctx.pInputType === 'P')
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            disabled: _ctx.pDisabledYn,
            class: "JOCommonInputStyle",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mInputObj.val) = $event)),
            type: "password",
            name: "",
            id: ""
          }, null, 8, _hoisted_3)), [
            [_vModelText, _ctx.mInputObj.val]
          ])
        : (_ctx.pInputType === 'S')
          ? _withDirectives((_openBlock(), _createElementBlock("select", {
              key: 3,
              disabled: _ctx.pDisabledYn,
              class: "JOCommonInputStyle",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mInputObj.val) = $event)),
              name: "",
              id: "selectInput",
              onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeInput()))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pInputObj.selectValueList, (opt, index) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: index,
                  value: opt.value
                }, _toDisplayString(opt.title), 9, _hoisted_5))
              }), 128))
            ], 40, _hoisted_4)), [
              [_vModelSelect, _ctx.mInputObj.val]
            ])
          : (_ctx.pInputType === 'T')
            ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                key: 4,
                onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.resize && _ctx.resize(...args))),
                ref: "commonTextArea",
                placeholder: _ctx.pPlaceHolder,
                class: "JOCommonInputStyle textarea",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.mInputObj.val) = $event)),
                name: "",
                id: "",
                cols: "30",
                rows: "10"
              }, null, 40, _hoisted_6)), [
                [_vModelText, _ctx.mInputObj.val]
              ])
            : _createCommentVNode("", true)
}