import { createStore } from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import JO_USER from './JOUserStore'
import JO_JOSA from './JOJosaStore'
import JO_MAP from './JOMapStore'
const storageState = createPersistedState({
  paths: ['JO_USER', 'JO_JOSA'],
  storage: window.localStorage
  /* key: 'vuexStore',
  storage: window.sessionStorage */
})
const store = createStore({
  modules: {
    // 키: 값 형태로 저장됩니다.
    JO_USER: JO_USER,
    JO_JOSA: JO_JOSA,
    JO_MAP: JO_MAP
  },
  plugins: [storageState]
})

export default store

/*
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
}) */
