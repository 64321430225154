import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/common/JOCloseIcon.svg'


const _withScopeId = n => (_pushScopeId("data-v-24bc4ee3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "JOPopHeader" }
const _hoisted_2 = { class: "commonTitleText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.pPopTitle), 1),
    (!_ctx.pJosaDetailPageYn)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "JOPopClose",
          src: _imports_0,
          alt: "",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pClosePop && _ctx.pClosePop(...args)))
        }))
      : _createCommentVNode("", true)
  ]))
}