const JOConst = {
  VALUE_TAG_TYPE_IMAGE: 'G',
  VALUE_TAG_TYPE_INPUT_TEXT: 'I',
  VALUE_TAG_TYPE_TEXTAREA: 'T',
  VALUE_TAG_TYPE_INPUT_NUMBER: 'N',
  VALUE_TAG_TYPE_SELECT: 'S',
  VALUE_TAG_TYPE_SELECT_BUTTON: 'SB',
  VALUE_TAG_TYPE_PASSWORD: 'P',

  VALI_CHECK_TYPE_NOT_NULL: 'NULL',
  VALI_CHECK_TYPE_MAX_SIZE: 'MAX',
  VALI_CHECK_TYPE_MIN_SIZE: 'MIN',
  VALI_CHECK_TYPE_ONLY_NUMBER: 'NUM',
  VALI_CHECK_TYPE_SAME_VALUE: 'T&F',
  VALI_CHECK_TYPE_PER: 'PER'
}

/*
josaStatus
10: 육안조사 진행중
19: 육안조사로 모든 조사종료

20: 육안조사후 실측조사중
99: 실측조사 및 모든 조사종료

dangerLevel
HH : 매우
MH : 보통
ML : 약간
LL : 불필요

 */
// T&F: true/false
// NULL
// MIN
// MAX

export default {
  install (Vue : any) {
    Vue.config.globalProperties.$JOConst = JOConst
  }
}
