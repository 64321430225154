import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/images/common/JOPageArrow.svg'


const _withScopeId = n => (_pushScopeId("data-v-091648c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "commonNormalText JOBorderTop JOPagenationWrap"
}
const _hoisted_2 = {
  class: "commonNormalText fontBold",
  style: {"position":"absolute"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.pPageInfo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, "전체: " + _toDisplayString(_ctx.pPageInfo.totalElements) + "개", 1),
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pageMove('prev'))),
          class: "JOArrowImg",
          style: {"transform":"rotate(180deg)"},
          src: _imports_0,
          alt: ""
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.mCurrentPage) + " / " + _toDisplayString(_ctx.pPageInfo && _ctx.pPageInfo.totalPages ? _ctx.pPageInfo.totalPages : 1) + " ", 1),
        _createElementVNode("img", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pageMove('next'))),
          class: "JOArrowImg",
          src: _imports_0,
          alt: ""
        })
      ]))
    : _createCommentVNode("", true)
}